import * as React from "react";

import styled from "styled-components";

import Layout from "../components/layout";
import VideoJS from "../components/player";

const Wrapper = styled.div`
  margin: 3em;
  display: flex;
`;

const Playlist = styled.div`
  width: 100%;
`;

export default function Artist({ pageContext }) {
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: false,
    fluid: false,
    techOrder: ["youtube"],
    // video.js needs an initial source
    sources: [
      {
        type: "video/youtube",
        src: `https://www.youtube.com/watch?v=${pageContext.videos[0]}`,
      },
    ],
  };

  const playlist = pageContext.videos.map((video: any) => {
    return {
      // TODO: add thumbnail: https://github.com/videojs/videojs-playlist-ui/blob/main/example.html
      name: `TODO: display title ${video}`,
      sources: [
        {
          type: "video/youtube",
          src: `https://www.youtube.com/watch?v=${video}`,
        },
      ],
    };
  });

  // TODO: render controls for next , prev, shuffle etc.
  return (
    <Layout>
      <Wrapper>
        <VideoJS options={videoJsOptions} playlist={playlist} />
        <Playlist className="vjs-playlist"></Playlist>
      </Wrapper>
    </Layout>
  );
}
